export default class UsersList {

    constructor(container) {
        this.usersContainer = document.getElementById("usersContainer");
        this.selectUsersStatus = document.getElementById("selectUsersStatus");
        this.searchInput = document.getElementById("searchInput");

        this.initList()
        this.initSearch()
        this.initAddUserForm()
        this.initDeleteUser()
    }

    initAddUserForm() {
        $("#user_enabled_from, #user_enabled_until, #user_birthdate").daterangepicker({
            "singleDatePicker": true,
            "showDropdowns": true,
            "autoApply": true,
            "locale": {
                "format": 'DD/MM/YYYY'
            }
        });

        /*
        * Submit
        */

        const create_user_modale = $("#create-collaborateur");
        const form_create_user = document.getElementById("form_create_user");
        const url = form_create_user.dataset.route;

        const image_input = document.getElementById("user_image");
        const image_input_b64 = document.getElementById("user_image_input_b64");
        const user_image_resize = document.getElementById('user_image_resize')

        const resize = $(user_image_resize).croppie({
            viewport: {
                width: 264,
                height: 264,
            }
        });

        resize.on('update.croppie', function() {
            resize.croppie('result', 'blob').then(function(blob) {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function() {
                    image_input_b64.value = reader.result;
                }
            });
        });

        const onInputChange = () => {
            if (image_input.files.length && image_input.files[0]) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    resize.croppie('bind', {url: e.target.result})
                    image_input_b64.value = e.target.result;
                    user_image_resize.style.display = 'block';
                }
                reader.readAsDataURL(image_input.files[0]);
            }
        }
        create_user_modale.on('shown.bs.modal',() => onInputChange());

        image_input.addEventListener('change', (e) => onInputChange())


        const name = document.getElementById("user_name");
        const firstname = document.getElementById("user_firstname");
        const email = document.getElementById("user_email");
        const birthdate = document.getElementById("user_birthdate");
        const job = document.getElementById("user_job");
        const office = document.getElementById("user_office");
        const phone = document.getElementById("user_phone");
        const mobile = document.getElementById("user_mobile");
        const enabled_from = document.getElementById("user_enabled_from");
        const enabled_until = document.getElementById("user_enabled_until");

        const badges = document.querySelectorAll('input[name=badges]');

        const user_errors = document.getElementsByClassName("user-error");


        form_create_user.addEventListener("submit", function (e) {
            e.preventDefault();

            for (let err of user_errors) {
                err.innerHTML = "";
            }

            const formData = new FormData();

            formData.append("image", image_input_b64.value);
            formData.append("name", name.value);
            formData.append("firstname", firstname.value);
            formData.append("email", email.value);
            formData.append("birthdate", birthdate.value);
            formData.append("job", job.value);
            formData.append("office", office.value);
            formData.append("phone", phone.value);
            formData.append("mobile", mobile.value);

            for (let cb of badges){
                if (cb.checked) formData.append("badges[]", cb.value);
            }

            formData.append("role", document.querySelector('input[name=role]:checked').value);
            formData.append("enabled_from", enabled_from.value)
            formData.append("enabled_until", enabled_until.value)

            Api.post(url, {
                headers: {
                    "Accept" : "application/json"
                },
                formData: formData,
                success: (xhr)  => {
                    create_user_modale.modal("hide");
                    form_create_user.reset();
                    showSuccess("L'utilisateur a bien été crée. Vous allez etre redirigés ...", ()=>{
                        document.location.href = xhr.responseText;
                    })
                },
                invalid: (xhr)  => {
                    const jsonerror = JSON.parse(xhr.responseText);
                    console.log(jsonerror);
                    if (jsonerror.errors){
                        Object.keys(jsonerror.errors).forEach(function(key) {
                            const target = document.getElementById("user-error-"+key);
                            target.innerHTML = jsonerror.errors[key][0]

                        })
                    }
                }
            })
        })
    }




    loadUsers(url) {
        Api.get(url, {
            success: (xhr)  => {
                this.usersContainer.innerHTML = xhr.responseText;
            }
        });
    }

    initList() {
        const loadUrl = this.usersContainer.dataset.url;
        this.loadUsers(loadUrl);

        $("body").on("click", "a.page-link",  (e) => {
            e.preventDefault();
            if (e.target.href){
                this.loadUsers(e.target.href);
            }
        })

        if (this.selectUsersStatus) this.selectUsersStatus.addEventListener("change",  () => {
            const newEndpoint = this.selectUsersStatus.value;
            this.loadUsers(newEndpoint);
        })
    }

    initSearch() {
        this.timer = null;
        this.searchInput.addEventListener("keyup",  (e) => {
            clearTimeout(this.timer);
            this.timer = setTimeout( () => {
                if (this.searchInput.value) {
                    if (this.selectUsersStatus) this.selectUsersStatus.disabled = true;
                    this.loadUsers(this.searchInput.dataset.route+"?s="+encodeURIComponent(this.searchInput.value));
                } else {
                    if (this.selectUsersStatus) this.selectUsersStatus.disabled = false;
                    this.loadUsers(this.selectUsersStatus.value);
                }
            }, 500);
        });
    }

    initDeleteUser() {
        $("body").on("click", ".delete_user", function (e) {
            e.preventDefault();
            niceConfirm({
                title: "Supprimer l'utilisateur ?",
                success: function (hideModale) {
                    Api.startLoading();
                    Api.post(e.target.dataset.route, {
                        success: (xhr)  => {
                            hideModale();
                            Api.stopLoading();
                            showSuccess("L'utilisateur a bien été supprimé. Vous allez etre redirigés ...", ()=>{
                                document.location.reload();
                            })
                        }
                    });
                }
            })
        });
    }
}
