export default class Dashboard {

    constructor(container) {

        if (window.DEVMODE) console.log('Dashboard');
        this.container = container.querySelector('#dashboardContainer');
        this.isAdmin = this.container.dataset.admin === "1";

        this.newsfeed_items_grid = this.container.querySelector("#newsfeed-items-grid");

        this.base_feed_url = this.container.dataset.baseFeedUrl;
        this.feed_url = this.newsfeed_items_grid.dataset.update;
        this.paged = false;

        this.is_favorite_feed = this.container.dataset.isfavorite === "1";
        this.nofeed = this.container.dataset.nofeed === "1";
        this.noforms = this.container.dataset.noforms === "1";
        this.is_single = this.container.dataset.issingle === "1";

        this.initFeedLoading();
        this.initAutoFeedRefresh();

        this.initActionFavoriteNews();
        this.initActionLike();

        this.initActionDisplayComments();
        this.initActionSendComment();
        this.initActionEditComment();
        this.initActionDeleteComment();

        this.initActionDelete();

        this.initOpenEditPopups();

        this.bindEmptyFileInputsButton();
        this.bindOnFileSelect();

        if (!this.noforms){
            this.bindSubmitNewStatus();
            this.bindSubmitNewNews();
            this.initTinyMce();
        }

        this.bindSubmitEditStatus();
        this.bindSubmitEditNews();

    }

    initFeedLoading() {
        $('body').on("click", '#load-more-button', (e) => {
            e.preventDefault()
            Api.startLoading();

            const BtnTarget = e.currentTarget;
            BtnTarget.classList.add('is--loading');
            Api.get(this.feed_url + (!this.is_single ? `/${this.paged}` : ''), {
                success: (xhr) => {
                    this.paged = true;
                    const json = JSON.parse(xhr.responseText);
                    BtnTarget.classList.remove('is--loading');
                    this.newsfeed_items_grid.innerHTML += json.html;
                    this.feed_url = json.next_page;
                    if (!json.next_page) {
                        BtnTarget.style.display = "none"
                    }
                    $("#programmed_news article").addClass("post--programmed");
                    this.createGalleries()
                    $('[data-toggle="tooltip"]').tooltip()
                }
            });
        })

        $("#load-more-button").trigger('click');
    }

    initAutoFeedRefresh() {
        setInterval(() => {
            Api.get(this.base_feed_url, {
                success: (xhr) => {
                    const json = JSON.parse(xhr.responseText);
                    this.newsfeed_items_grid.innerHTML = json.html;
                    this.feed_url = json.next_page;
                    if (!json.next_page) {
                        document.getElementById('load-more-button').style.display = "none"
                    }
                    $("#programmed_news article").addClass("post--programmed");
                    this.createGalleries();
                    $('[data-toggle="tooltip"]').tooltip()
                }
            });
        }, 1000 * 60 * 15); //156mn
    }

    createGalleries() {

        $('.js-zoom-gallery').each(function () {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'inline',
                gallery: {
                  enabled: true
                },
                removalDelay: 500, //delay removal by X to allow out-animation
                callbacks: {
                  elementParse: function(item) {

                      if(item.el[0].classList.contains('is--video')) {
                        console.log("video");
                        item.type = 'inline';
                        const videoUrl = item.src;
                        item.src = '<div class="mfp-close"></div>'+
                          '<video controls>'+
                          '<source src="'+videoUrl+'" type="video/mp4">'+
                          '</video>';

                      }

                      if(item.el[0].classList.contains('is--image')) {
                        console.log("image");
                        item.type = 'image';
                      };

                  },
                  open: function(){
                    var video = this.contentContainer[0].querySelector('video');
                    if (video) video.play();
                  }
                },
                closeOnContentClick: false,
            });
        });
    }

    initActionFavoriteNews() {
        const is_favorite_feed = this.is_favorite_feed;
        $("body").on("click", ".fav_news_btn, .fav_status_btn", function (e) {
            e.preventDefault();
            const clicked = $(this);

            const parent = document.getElementById(clicked.data("parent"));

            const url = clicked.attr("data-url");
            Api.post(url, {
                success: (xhr) => {
                    clicked.toggleClass("favorited");
                    if (is_favorite_feed){
                        parent.parentNode.removeChild(parent);
                    }
                }
            });
        })
    }

    initActionLike() {
        $("body").on("click", ".like_btn", function (e) {
            e.preventDefault();
            var clicked = $(this);
            if (clicked.data("liked") === 1) return;

            const target = document.getElementById(clicked.data("target"));

            const url = clicked.attr("href");
            Api.post(url, {
                success: (xhr) => {
                    clicked.data("liked", 1);
                    clicked.addClass("liked");
                    target.innerHTML = xhr.responseText;
                }
            });
        })
    }

    initActionDisplayComments() {
        $("body").on("click", ".show_comments", function (e) {

            e.preventDefault();

            var clicked = $(this);
            var clickedBtn = e.currentTarget;
            if (clicked.data("clicked") === 1) return;

            const url = clicked.attr("href");
            const target = document.getElementById(clicked.data("target"));
            clickedBtn.classList.toggle('is--active');

            if (clickedBtn.classList.contains('is--active')) {

                Api.get(url, {
                    success: (xhr) => {
                        clicked.data("clicked", "1");
                        target.innerHTML = xhr.responseText;
                        $(target).find('.comments-list').scrollTop($(target).find('.comments-list').outerHeight());
                    }
                });
            } else {
                target.innerHTML = "";
            }
        })
    }

    initActionSendComment() {
        $("body").on("submit", ".comment-form", function (e) {
            e.preventDefault();
            Api.startLoading();

            var form = $(this);
            const url = form.data("url");
            const target = document.getElementById(form.data("target"));
            const spanCount = document.getElementById(form.data("count"));

            const body = form.find("textarea").val();
            const error = form.find(".invalid-feedback");
            error.hide();

            const formData = new FormData();
            formData.append("body", body);
            Api.post(url, {
                formData: formData,
                success: (xhr) => {
                    target.innerHTML = xhr.responseText;
                    spanCount.innerHTML = $(target).find(".comment-item").length + "";
                },
                invalid: (xhr) => {
                    const jsonerror = JSON.parse(xhr.responseText);
                    if (jsonerror.body) {
                        error.text(jsonerror.body[0]).show()
                    }
                }
            });
        })
    }

    initActionEditComment() {
        this.edit_comment_modal = $("#edit-comment");
        this.form_edit_comment = document.getElementById("form_edit_comment");
        this.edit_comment_body = document.getElementById("comment_body");
        this.edit_comment_errors= document.getElementsByClassName("edit-comment-error");

        $("body").on("click", ".comment_edit", function (e) {
            e.preventDefault();
            var clicked = $(this);

            const url = clicked.data("route");
            const text = document.getElementById(clicked.data("text")).innerHTML;
            this.edit_comment_body.value = text;
            this.form_edit_comment.action = url;
            this.form_edit_comment.dataset.target = clicked.data("text");

            this.edit_comment_modal.modal("show");
        })

        this.form_edit_comment.addEventListener("submit",  (e) => {
            e.preventDefault();
            Api.startLoading();
            for (let err of this.edit_comment_errors) {
                err.innerHTML = "";
            }

            const target = document.getElementById(this.form_edit_comment.dataset.target);
            const formData = new FormData();
            formData.append("body", this.edit_comment_body.value);

            Api.post(this.form_edit_comment.action, {
                formData: formData,
                headers:{
                    "Accept": "application/json"
                },
                success: (xhr)  => {
                    Api.stopLoading();
                    target.innerHTML = xhr.responseText;
                    this.edit_comment_modal.modal("hide");
                    showSuccess("Le commentaire a bien été mis à jour");
                },
                invalid: (xhr)  => {
                    const jsonerror = JSON.parse(xhr.responseText);
                    if (jsonerror.errors){
                        Object.keys(jsonerror.errors).forEach(function(key) {
                            const target = document.getElementById("error-edit-comment-"+key);
                            target.innerHTML = jsonerror.errors[key][0]
                        })
                    }
                }
            })
        })


    }

    initActionDeleteComment() {
        $("body").on("click", ".comment_remove", function (e) {
            e.preventDefault();
            Api.startLoading();
            var clicked = $(this);

            const target = document.getElementById(clicked.data("target"));
            const countSpan = document.getElementById(clicked.data("count"));
            const url = clicked.data("delete");

            niceConfirm({
                title: "Supprimer le commentaire ?",
                success: function (hideModale) {
                    Api.post(url, {
                        success: (xhr) => {
                            target.remove();
                            countSpan.innerHTML = (parseInt(countSpan.innerHTML) - 1) + ""
                            hideModale()
                        }
                    });
                }
            })
        })
    }

    initActionDelete() {
        $("body").on("click", ".post-delete", function (e) {
            e.preventDefault();
            var clicked = $(this);

            const url = clicked.attr("href");
            niceConfirm({
                success: function (hide) {
                    Api.startLoading();

                    Api.post(url, {
                        success: (xhr) => {
                            clicked.closest(".ui-block").remove();
                            Api.stopLoading();
                            hide();
                        }
                    })
                }
            })
        });
    }

    initOpenEditPopups() {
        this.edit_status_modal = $("#edit-status")
        this.form_edit_status = document.getElementById("form_edit_status");
        this.edit_status_medias = document.getElementById("edit_status_medias");
        this.edit_status_remove_files = document.getElementById("edit_status_remove_files");
        this.edit_status_preview_files = document.getElementById("edit_status_preview_files");
        this.edit_status_body = document.getElementById("edit_status_body");

        this.edit_news_modal = $("#edit-news")
        this.form_edit_news = document.getElementById("form_edit_news");
        this.edit_news_medias = document.getElementById("edit_news_medias");
        this.edit_news_remove_files = document.getElementById("edit_news_remove_files");
        this.edit_news_preview_files = document.getElementById("edit_news_preview_files");
        this.edit_news_body = document.getElementById("edit_news_body");
        this.edit_news_title = document.getElementById("edit_news_title");
        this.edit_news_category = document.getElementById("edit_news_category");

        /* Preview images in edit windows */
        const inputs_file_preview = this.container.querySelectorAll('.input-file-preview');
        for (let input_file of inputs_file_preview){
            input_file.addEventListener('change', (e) => {
                const preview_block = this.container.querySelector('#'+input_file.dataset.target);
                preview_block.classList.remove('js-zoom-gallery');
                preview_block.innerHTML = "";
                const files= input_file.files
                for (let file of files){
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        const atag = document.createElement("a");
                        atag.classList.add("col", "col-3-width");

                        var type = "other";
                        if (file.type.includes('image')) type="image";
                        else if (file.type.includes('video')) type="video";
                        var tagToAppend=null;

                        if (type === "image"){
                            tagToAppend = document.createElement("img");
                            tagToAppend.src = e.target.result;
                        } else if (type === "video"){
                            tagToAppend = document.createElement("video");
                            tagToAppend.src = URL.createObjectURL(file);
                        } else {
                            tagToAppend = document.createElement("a");
                            const logo = document.createElement('img');
                            logo.src = "/assets/img/filepreview.png";
                            tagToAppend.appendChild(logo);
                            tagToAppend.href = e.target.result;
                        }

                        atag.appendChild(tagToAppend);
                        preview_block.appendChild(atag);

                    }
                    reader.readAsDataURL(file);
                }
            })
        }

        const remove_all_images = this.container.querySelectorAll('.remove_all_images');
        for (let cb_remove of remove_all_images){
            cb_remove.addEventListener('change',(e) => {
                const preview_block = document.getElementById(cb_remove.dataset.preview);
                if  (cb_remove.checked){
                    preview_block.style.display = "none";
                } else {
                    preview_block.style.display = "block";
                }
            })
        }


        $("body").on("click", ".status_edit", (e) => {
            e.preventDefault();
            var clicked = $(e.target);

            const url = clicked.data("route");
            const text = this.stripHtml(document.getElementById(clicked.data("text")).innerHTML);
            const files = document.getElementsByClassName(clicked.data("files"));

            this.edit_status_body.value = text;

            this.edit_status_preview_files.innerHTML = "";
            this.edit_status_preview_files.classList.remove('js-zoom-gallery');

            if (files.length) {
                for (let file_link of files){

                    const type = file_link.dataset.type;

                    const atag = document.createElement("a");
                    atag.classList.add("col", "col-3-width");
                    atag.href = file_link.href;
                    var tagToAppend = null;
                    if (type === "image"){
                        tagToAppend = document.createElement("img");
                        tagToAppend.src = file_link.href;
                    } else if (type === "video"){
                        tagToAppend = document.createElement("video");
                        tagToAppend.src = file_link.href;
                    } else {
                        tagToAppend = document.createElement("a");
                        const logo = document.createElement('img');
                        logo.src = "/assets/img/filepreview.png";
                        tagToAppend.appendChild(logo);
                        tagToAppend.href = file_link.href;
                    }

                    atag.appendChild(tagToAppend);
                    this.edit_status_preview_files.appendChild(atag);
                }
                this.createGalleries();
            }

            this.form_edit_status.action = url;
            this.form_edit_status.dataset.target = clicked.data("target");

            this.edit_status_modal.modal("show");
        })

        $("body").on("click", ".news_edit", (e) => {
            e.preventDefault();
            var clicked = $(e.target);

            const url = clicked.data("route");
            const title = document.getElementById(clicked.data("title")).innerHTML;
            const text = document.getElementById(clicked.data("text")).innerHTML;
            const category_id = clicked.data("category");
            const files = document.getElementsByClassName(clicked.data("files"));


            this.edit_news_title.value = title;
            this.edit_news_category.value = category_id;
            tinymce.get('edit_news_body').setContent(text);
            this.edit_news_preview_files.innerHTML = "";
            this.edit_news_preview_files.classList.remove('js-zoom-gallery');
            if (files.length) {
                for (let file_link of files){

                    const type = file_link.dataset.type;

                    const atag = document.createElement("a");
                    atag.classList.add("col", "col-3-width");
                    atag.href = file_link.href;
                    var tagToAppend = null;
                    if (type === "image"){
                        tagToAppend = document.createElement("img");
                        tagToAppend.src = file_link.href;
                    } else if (type === "video"){
                        tagToAppend = document.createElement("video");
                        tagToAppend.src = file_link.href;
                    } else {
                        tagToAppend = document.createElement("a");
                        const logo = document.createElement('img');
                        logo.src = "/assets/img/filepreview.png";
                        tagToAppend.appendChild(logo);
                        tagToAppend.href = file_link.href;
                    }

                    atag.appendChild(tagToAppend);
                    this.edit_news_preview_files.appendChild(atag);
                }
                this.createGalleries();
            }

            this.form_edit_news.action = url;
            this.form_edit_news.dataset.target = clicked.data("target");

            this.edit_news_modal.modal("show");
        })
    }

    bindEmptyFileInputsButton() {
        $("body").on("click", ".remove-file", (e) => {
            e.preventDefault();
            var clicked = $(e.target);
            const target = clicked.data('target');
            const input = document.getElementById(target);
            input.value = "";
            this.onFileChange(input)
        })
    }

    bindOnFileSelect() {
        const inputs = this.container.querySelectorAll("#news_medias, #status_medias");
        for (let inp of inputs) {
            inp.addEventListener('change', (e) => this.onFileChange(e.target))
        }
    }

    onFileChange(input){
        const target = document.getElementById(input.dataset.target);
        if (input.files.length){
            target.innerHTML = "";
            for (let file of input.files){
                target.innerHTML += file.name+"<br>";
            }
        } else {
            target.innerHTML = "<i>Aucun fichier séléctionné</i>";
        }
    }

    bindSubmitNewStatus() {
        this.status_form = document.getElementById("status_form");
        this.status_submit = document.getElementById("status_submit");
        this.status_message = document.getElementById("status_message");
        this.status_medias = document.getElementById("status_medias");
        this.error_status = document.getElementById("error_status");
        this.error_status_medias = document.getElementById("error_status_medias");

        if (this.status_form){
            this.status_form.addEventListener("submit",  (e) => {
                e.preventDefault();
                this.status_submit.classList.add('waiting')
                Api.startLoading()
                this.error_status.style.display="none";
                this.error_status_medias.style.display="none";

                const url = this.status_form.dataset.url;

                const message = this.status_message.value;

                var formData = new FormData();
                formData.append("message", message);
                if (this.status_medias.files.length) {
                    for (let file of this.status_medias.files){
                        formData.append("medias[]", file);
                    }
                }

                const loaderText = this.news_submit.querySelector('.loader span');
                Api.post(url, {
                    formData: formData,
                    onprogress: (e) => {
                        loaderText.innerHTML = parseInt((e.loaded / e.total)*100)+'%';
                    },
                    success: (xhr)  => {
                        this.newsfeed_items_grid.innerHTML = xhr.responseText+this.newsfeed_items_grid.innerHTML;
                        this.status_form.reset();
                        this.createGalleries();
                        this.status_submit.classList.remove('waiting');
                        Api.stopLoading();
                    },
                    invalid: (xhr)  => {
                        const jsonerror = JSON.parse(xhr.responseText);
                        if (jsonerror.message){
                            this.error_status.style.display="block";
                            this.error_status.innerHTML = jsonerror.message[0];
                        }
                        if (jsonerror.medias){
                            this.error_status_medias.style.display="block";
                            this.error_status_medias.innerHTML = jsonerror.medias[0];
                        }
                        this.status_submit.classList.remove('waiting');
                        Api.stopLoading()
                    }
                });
            })
        }

    }

    bindSubmitEditStatus(){

        this.edit_status_errors= document.getElementsByClassName("edit-status-error");

        this.form_edit_status.addEventListener("submit",  (e) => {
            e.preventDefault();
            Api.startLoading();

            for (let err of this.edit_status_errors) {
                err.innerHTML = "";
            }

            const target = document.getElementById(this.form_edit_status.dataset.target);

            const formData = new FormData();
            formData.append("message", this.edit_status_body.value);
            if (this.edit_status_remove_files.checked) {
                formData.append("remove_files", "on");
            } else if (this.edit_status_medias.files){
                for (let file of this.edit_status_medias.files){
                    formData.append("medias[]", file);
                }
            }

            Api.post(this.form_edit_status.action, {
                formData: formData,
                headers:{
                    "Accept": "application/json"
                },
                success: (xhr)  => {
                    Api.stopLoading();
                    target.outerHTML = xhr.responseText;
                    this.edit_status_modal.modal("hide");
                    showSuccess("Le statut a bien été mis à jour");
                    this.form_edit_status.reset();
                    this.edit_status_preview_files.innerHTML = "";
                    this.createGalleries();
                },
                invalid: (xhr)  => {
                    const jsonerror = JSON.parse(xhr.responseText);
                    if (jsonerror.errors){
                        Object.keys(jsonerror.errors).forEach(function(key) {
                            const target = document.getElementById("error-edit-status-"+key);
                            target.innerHTML = jsonerror.errors[key][0]
                        })
                    }
                }
            })
        })
    }

    bindSubmitNewNews() {
        this.news_form = document.getElementById("news_form");
        this.news_submit = document.getElementById("news_submit");
        this.news_category = document.getElementById("news_category");
        this.news_category_add_block = document.getElementById("news_category_add_block");
        this.news_category_add = document.getElementById("news_category_add");
        this.news_title = document.getElementById("news_title");
        this.news_message = document.getElementById("news_message");
        this.news_medias = document.getElementById("news_medias");
        this.error_news_title = document.getElementById("error_news_title");
        this.error_news_message = document.getElementById("error_news_message");
        this.error_news_medias = document.getElementById("error_news_medias");
        this.error_news_publish_at = document.getElementById("error_news_publish_at");
        this.error_news_category_add = document.getElementById("error_news_category_add");

        this.cb_news_planned = document.getElementById("news_planned");
        this.block_news_planned = this.cb_news_planned ? document.getElementById(this.cb_news_planned.dataset.target) : null;

        this.news_planned_datetime = document.getElementById("news_planned_datetime");

        /* Bind show/hide add category field */
        this.news_category.addEventListener('change', () => this.onCategorgyChange());
        this.onCategorgyChange()

        $(this.news_planned_datetime).daterangepicker({
            "singleDatePicker": true,
            "timePicker": true,
            "timePicker24Hour": true,
            "showDropdowns": true,
            "autoApply": true,
            "locale": {
                "format": 'DD/MM/YYYY HH:mm'
            }
        });

        if (this.cb_news_planned) this.cb_news_planned.addEventListener("change", () => this.onToggleProgrammedNews());
        this.onToggleProgrammedNews();

        this.news_form.addEventListener("submit",  (e) => {
            e.preventDefault();
            this.news_submit.classList.add('waiting')
            Api.startLoading()
            tinymce.get(this.news_message.id).save();

            this.error_news_title.style.display="none";
            this.error_news_message.style.display="none";
            this.error_news_medias.style.display="none";
            this.error_news_publish_at.style.display="none";
            if (this.error_news_category_add) this.error_news_category_add.style.display="none";

            const url = this.news_form.dataset.url;

            const title = this.news_title.value;
            const message = this.news_message.value;
            const category_id = this.news_category.value;

            var formData = new FormData();
            formData.append("title", title);
            formData.append("message", message);
            formData.append("category_id", category_id);
            if (this.news_category_add && this.news_category_add.value){
                formData.append("category_add", this.news_category_add.value);
            }
            if (this.news_medias.files.length) {
                for (let file of this.news_medias.files){
                    formData.append("medias[]", file);
                }
            }
            if (this.cb_news_planned.checked) {
                formData.append("publish_at", this.news_planned_datetime.value);
            }
            const loaderText = this.status_submit.querySelector('.loader span');
            Api.post(url, {
                formData: formData,
                onprogress: (e) => {
                    console.log(parseInt((e.loaded / e.total)*100)+'%');
                    loaderText.innerHTML = parseInt((e.loaded / e.total)*100)+'%';
                },
                success: (xhr) => {
                    this.newsfeed_items_grid.innerHTML = xhr.responseText+this.newsfeed_items_grid.innerHTML;
                    this.news_form.reset();
                    this.onCategorgyChange();
                    this.createGalleries();
                    Api.stopLoading();
                    this.news_submit.classList.remove('waiting')
                },
                invalid: (xhr)  => {
                    const jsonerror = JSON.parse(xhr.responseText);
                    if (jsonerror.title){
                        this.error_news_title.style.display="block";
                        this.error_news_title.innerHTML = jsonerror.message[0];
                    }

                    if (jsonerror.message){
                        this.error_news_message.style.display="block";
                        this.error_news_message.innerHTML = jsonerror.message[0];
                    }
                    if (jsonerror.medias){
                        this.error_news_medias.style.display="block";
                        this.error_news_medias.innerHTML = jsonerror.medias[0];
                    }
                    if (jsonerror.publish_at){
                        this.error_news_publish_at.style.display="block";
                        this.error_news_publish_at.innerHTML = jsonerror.publish_at[0];
                    }
                    if (jsonerror.category_add){
                        this.error_news_category_add.style.display="block";
                        this.error_news_category_add.innerHTML = jsonerror.category_add[0];
                    }
                    Api.stopLoading()
                    this.news_submit.classList.remove('waiting')
                }
            });
        })

    }

    bindSubmitEditNews(){
        this.edit_news_errors= document.getElementsByClassName("edit-news-error");

        this.form_edit_news.addEventListener("submit",  (e) => {
            e.preventDefault();
            Api.startLoading();
            tinymce.get(this.edit_news_body.id).save();

            for (let err of this.edit_news_errors) {
                err.innerHTML = "";
            }

            const target = document.getElementById(this.form_edit_news.dataset.target);

            const formData = new FormData();
            formData.append("title", this.edit_news_title.value);
            formData.append("message",this.edit_news_body.value);
            formData.append("category_id", this.edit_news_category.value);
            if (this.edit_news_remove_files.checked) {
                formData.append("remove_files", "on");
            } else if (this.edit_news_medias.files){
                for (let file of this.edit_news_medias.files){
                    formData.append("medias[]", file);
                }
            }

            Api.post(this.form_edit_news.action, {
                formData: formData,
                headers:{
                    "Accept": "application/json"
                },
                success: (xhr)  => {
                    Api.stopLoading();
                    //tinymce.get('edit_news_body').save();

                    target.outerHTML = xhr.responseText;
                    this.edit_news_modal.modal("hide");
                    showSuccess("L'actualité a bien été mis à jour");
                    this.form_edit_news.reset();
                    this.edit_news_preview_files.innerHTML = "";
                    this.createGalleries();
                },
                invalid: (xhr)  => {
                    const jsonerror = JSON.parse(xhr.responseText);
                    if (jsonerror.errors){
                        Object.keys(jsonerror.errors).forEach(function(key) {
                            const target = document.getElementById("error-edit-news-"+key);
                            target.innerHTML = jsonerror.errors[key][0]
                        })
                    }
                }
            })
        })
    }

    onToggleProgrammedNews(e) {
        const is_planned = this.cb_news_planned.checked;
        this.block_news_planned.style.display = is_planned ? "block" : "none";
        this.news_planned_datetime.required = is_planned;
    }

    initTinyMce() {
        if (typeof(tinymce) !== 'undefined'){
            tinymce.init({
                language : 'fr_FR',
                menubar: false,
                language_url : this.container.dataset.tinymcelanguage,
                selector:'.news_message',
                relative_urls : false,
                remove_script_host : true,
                plugins: 'lists link table autosave',
                autosave_interval: "20s",
                autosave_retention: "1000m",
                autosave_restore_when_empty: true,
                toolbar: 'bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist link restoredraft',
            });
        }

    }

    onCategorgyChange() {
        if (!this.isAdmin) return;
        const value = this.news_category.value;
        this.news_category_add_block.style.display = (value === 'other') ? 'block' : 'none'
        this.news_category_add.required = (value === 'other');
    }

    stripHtml(html)
    {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
}
