import Dashboard from './Dashboard.js';
import Mediatheque from "./Mediatheque";
import NotificationManager from "./NotificationManager.js";
import UserProfile from "./UserProfile";
import UsersList from "./UsersList";

window.DEVMODE = DEVMODE === 'development';

class App {

    constructor() {

        // Signature Wokine
        console.log('%cCreated by Wokine, with ❤', 'color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;');
        console.log('%c⚡ http://wokine.com ⚡', 'color:#ccc');

        this.init();
    }

    init() {
        document.addEventListener('DOMContentLoaded', () => {

            if (window.DEVMODE) console.log('DOMContentLoaded');
            const mainContainer = document.getElementById("mainContainer");
            if (!mainContainer) return;

            new NotificationManager();

            const route = mainContainer.dataset.route;
            switch (route) {
                case 'dashboard':
                    new Dashboard(mainContainer);
                    break;
                case 'mediatheque':
                    new Mediatheque(mainContainer);
                    break;
                case 'user-profile':
                    new UserProfile(mainContainer);
                    break;
                case 'users-list':
                    new UsersList(mainContainer);
                    break;
                default:
                    break;
            }

        }, false);

    }
}

new App();
