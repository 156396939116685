export default class UserProfile {

    constructor(container) {
        this.bindBirtdayCheckbox()
        this.initDatePickers()
        this.initProfileImageUpload()
    }

    bindBirtdayCheckbox() {
        const cbs = document.getElementsByClassName('cb_birthday');
        if (cbs){
            for (let cb of cbs){
                cb.addEventListener('change', () =>{
                    Api.startLoading()
                    cb.disabled = true;
                    const formData = new FormData;
                    formData.append('show', cb.checked);
                    Api.post(cb.dataset.ajaxurl, {
                        formData: formData,
                        success: (xhr) => {
                            Api.stopLoading();
                            cb.disabled = false;
                            showSuccess(cb.checked ? 'Votre anniversaire s\'affichera désormais sur votre profil et dans le calendrier'
                                :
                                'Votre anniversaire ne s\'affichera plus sur votre profil et dans le calendrier'
                            );
                        },
                        error: (xhr) => {
                            Api.stopLoading();
                            cb.disabled = false;
                        }
                    })
                })
            }
        }
    }

    initDatePickers() {
        $("#enabled_from, #enabled_until, #birthday").daterangepicker({
            "singleDatePicker": true,
            "showDropdowns": true,
            "autoApply": true,
            "locale": {
                "format": 'DD/MM/YYYY'
            }
        });
    }

    initProfileImageUpload() {

        /*
        * 1/ user clicks on user_pic_input and pick file
        * 2/ Opens modal with croppie inside and set image from uploaded file
        * 3/ On croppie update, set preview (behind modal) and update user_pic_input_B64 with base64 cropped result
        * 4/ Send user_pic_input_B64 when form submited. user_pic_input ignored on backend
        * */

        const user_pic_input = document.getElementById("user_pic_input");
        const user_pic_input_b64 = document.getElementById("user_pic_input_b64");

        const user_pic = document.getElementById("user_pic");
        const user_pic_resize = document.getElementById("user_pic_resize");
        const resize = $('#user_pic_resize').croppie({
            viewport: {
                width: 264,
                height: 264,
            },
        });

        user_pic_input.addEventListener("change", function (e) {

            if (user_pic_input.files.length && user_pic_input.files[0]) {
                var reader = new FileReader();

                reader.onload = function(e) {
                    user_pic.src = e.target.result;
                    user_pic_input_b64.value = e.target.result;

                    $( "#resize-img" ).on('shown.bs.modal', function(){
                        resize.croppie('bind', {url: e.target.result})

                        resize.on('update.croppie', function() {
                            resize.croppie('result', 'blob').then(function(blob) {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onloadend = function() {
                                    user_pic.src = reader.result;
                                    user_pic_input_b64.value = reader.result;
                                }
                            });
                        });                    });
                    $('#resize-img').modal('show')
                }

                reader.readAsDataURL(user_pic_input.files[0]);
            }
        });
    }
}
