export default class Mediatheque {

    constructor(container) {

        if (window.DEVMODE) console.log('Mediatheque');
        this.container = container.querySelector('#mediathequeContainer');



        this.isAdmin = this.container.dataset.admin === "1";

        this.root_folders = document.getElementsByClassName("root_folder");
        this.lasturl = this.root_folders[0].href;

        this.folder_content = document.getElementById("folder_content");
        this.menu_mediatheque = document.getElementById("menu_mediatheque");

        this.modal_research = $("#resultsSearch");
        this.search_form = document.getElementById("search_form");
        this.searchInput = document.getElementById("searchInput");
        this.search_result = document.getElementById("search_result");


        this.initActionDisplayFolderContent();
        this.initSearch();
        this.updateGallery();



        if (this.isAdmin){

            this.modal_add_file = $("#add-file");
            this.add_file_groupid = document.getElementById("add_file_groupid");
            this.add_file_groupname = document.getElementById("add_file_groupname");

            this.modale_edit_folder = $("#edit-folder");
            this.edit_title = document.getElementById("folder_edit_title");
            this.parent_edit_folder = $("#parent_edit_folder");

            this.form_add_file = document.getElementById("form_add_file");
            this.group_id = document.getElementById("add_file_groupid");

            this.title_bloc = document.getElementById("title_bloc");
            this.file_title = document.getElementById("file_title");

            this.document_bloc = document.getElementById("document_bloc");
            this.gallery_bloc = document.getElementById("gallery_bloc");
            this.file_document = document.getElementById("file_document");
            this.files_gallery = document.getElementById("files_gallery");

            this.file_errors = document.getElementsByClassName("file-error");

            this.modale_add_folder = $("#add-folder");
            this.form_add_folder = document.getElementById("form_add_folder");
            this.title = document.getElementById("folder_title");
            this.parent_folder = $("#parent_folder");
            this.folder_errors = document.getElementsByClassName("folder-error");

            this.form_edit_folder = document.getElementById("form_edit_folder");
            this.folder_edit_errors = document.getElementsByClassName("folder-error");

            this.initSortableMenu();
            this.updateSortableSubfolders()
            this.initAddFilePopup();
            this.initEditFolderPopup();
            this.initActionDeleteFolder();
            this.initActionDeleteFile();

            this.onAddFileSubmit();
            this.onAddFolderSubmit();
            this.onEditFolderSubmit();

            this.bindOnTypeChange();
            this.onTypeChange();



        }
    }

    initActionDisplayFolderContent() {
        $("body").on("click", ".root_folder",  (e)  => {
            e.preventDefault();
            startLoading();
            const url = e.target.href;
            for (let item of this.root_folders) {
                item.parentNode.classList.remove("is--active")
            }
            e.target.parentNode.classList.add("is--active");

            Api.get(url, {
                success:  (xhr) => {
                    this.lasturl = url;
                    this.folder_content.innerHTML = xhr.responseText;
                    this.updateSortableSubfolders();
                    this.updateGallery()
                }
            });
        });
    }

      /*
      * Reorder drag'n drop subfolders
      * */
    updateSortableSubfolders() {
        if (!this.isAdmin) return;
        const sortableSubfolders = new Sortable.default(this.folder_content, {
            delay: 200,
            draggable: '.accordion',
        });

        sortableSubfolders.on("sortable:stop",  (e) => {
            startLoading();
            const ordered_ids = [];
            const items = sortableSubfolders.getDraggableElementsForContainer(e.newContainer);
            for (let item of items) {
                ordered_ids.push(item.dataset.groupid);
            }
            const formData = new FormData();
            ordered_ids.forEach(function (id) {
                formData.append("groups_id[]", id);
            })
            Api.post(this.menu_mediatheque.dataset.route, {
                formData: formData,
                headers: {
                    Accept: "application/json"
                },
                success: (xhr) => {
                    stopLoading();
                },
                invalid: (xhr) => {
                    showError('Une erreur est survenue.');
                    stopLoading();
                }
            })
        })
    }

    initSearch() {
        this.search_form.addEventListener("submit",  (e) => {
            e.preventDefault();
            if (this.searchInput.value.trim() === "") return;
            startLoading();
            const url = e.target.dataset.route + "?s=" + this.searchInput.value;
            Api.get(url, {
                success: (xhr) => {
                    this.search_result.innerHTML = xhr.responseText;
                    this.modal_research.modal("show");
                    stopLoading();
                }
            })

        })
    }

    initAddFilePopup() {
        if (!this.isAdmin) return;

        $("body").on("click", ".open_add_file",  (e) => {
            e.preventDefault();
            const group_id = e.target.dataset.groupid;
            const group_name = e.target.dataset.groupname;
            this.add_file_groupid.value = group_id;
            this.add_file_groupname.value = group_name;
            this.modal_add_file.modal("show")
        })
    }

    initEditFolderPopup() {
        if (!this.isAdmin) return;

        $("body").on("click", ".folder_edit", (e) => {
            e.preventDefault();
            const group_name = e.target.dataset.title;
            this.edit_title.value = group_name;
            this.parent_edit_folder.find("option").css("display", "block");
            this.parent_edit_folder.find("option[value=" + e.target.dataset.id + "]").css("display", "none");
            this.parent_edit_folder.val(e.target.dataset.parent);
            this.parent_edit_folder.selectpicker('refresh');
            this.form_edit_folder.dataset.route = e.target.dataset.route;
            this.modale_edit_folder.modal("show")
        })
    }

    initActionDeleteFolder() {
        $("body").on("click", ".delete_folder",  (e) => {
            e.preventDefault();
            niceConfirm({
                title: "Supprimer le dossier, ses sous-dossiers, et et medias ?",
                success: function (hideModale) {
                    startLoading();
                    Api.post(e.target.dataset.route, {
                        success: (xhr) => {
                            hideModale();
                            stopLoading();
                            showSuccess("Le dossier a bien été supprimé. Vous allez etre redirigés ...", () => {
                                document.location.reload();
                            })
                        }
                    });
                }
            })
        });
    }

    initActionDeleteFile() {
        $("body").on("click", ".delete_file",  (e) => {
            e.preventDefault();
            niceConfirm({
                title: "Supprimer ce fichier ?",
                success: function (hideModale) {
                    startLoading();
                    Api.post(e.target.dataset.route, {
                        success: (xhr) => {
                            $(e.target).closest(".fileblock").remove()
                            hideModale();
                            stopLoading();
                            showSuccess("Le fichier a bien été supprimé.")
                        }
                    });
                }
            })
        });
    }

    initSortableMenu() {
        const sortableMenu = new Sortable.default(menu_mediatheque, {
            delay: 200,
            draggable: '.ui-block-title ',
        });

        sortableMenu.on("sortable:stop", (e) => {
            startLoading();
            const ordered_ids = [];
            const items = sortableMenu.getDraggableElementsForContainer(e.newContainer);
            for (let item of items){
                if (item.classList.contains("ui-block-title")) ordered_ids.push(item.dataset.groupid);
            }
            const formData = new FormData();
            ordered_ids.forEach(function (id) {
                formData.append("groups_id[]", id);
            })

            Api.post(this.menu_mediatheque.dataset.route, {
                formData: formData,
                headers: {
                    Accept: "application/json"
                },
                success: (xhr)  => {
                    stopLoading();
                },
                invalid: (xhr)  => {
                    showError('Une erreur est survenue.');
                    stopLoading();
                }
            })
        })
    }

    onAddFileSubmit() {
        const submit = this.form_add_file.querySelector('button[type=submit]')
        const submitText = submit.innerHTML;

        this.form_add_file.addEventListener("submit",  (e) => {
            e.preventDefault();
            for (let err of this.file_errors) {
                err.innerHTML = "";
            }
            const type = document.querySelector('input[name=type]:checked').value;

            const formData = new FormData();
            formData.append("group_id", this.group_id.value);
            formData.append("type", type);

            if (type === "document") {
                formData.append("title", this.file_title.value);
                formData.append("document", this.file_document.files[0]);
            } else {
                Array.from(this.files_gallery.files).forEach((f) => {
                    formData.append("gallery[]", f);
                })
            }
            submit.disabled = true;
            Api.post(this.form_add_file.dataset.route, {
                formData: formData,
                onprogress: (e) => {
                    submit.innerHTML = parseInt((e.loaded / e.total)*100)+'%';
                },
                success: (xhr)  => {
                    submit.innerHTML = submitText;
                    submit.disabled = false;
                    Api.get(this.lasturl, {
                        success: (xhr) => {
                            this.folder_content.innerHTML = xhr.responseText;
                            this.modal_add_file.modal("hide");
                            if (type === "document") showSuccess("Le fichier a bien été ajouté");
                            else showSuccess("Les images ont bien été ajoutées.");
                            this.form_add_file.reset()
                            this.updateGallery()
                        }
                    });
                },
                invalid: (xhr)     => {
                    const jsonerror = JSON.parse(xhr.responseText);
                    if (jsonerror.errors){
                        Object.keys(jsonerror.errors).forEach(function(key) {
                            const target = document.getElementById("file-error-"+key);
                            target.innerHTML = jsonerror.errors[key][0]
                        })
                    }
                }
            });

        });
    }

    bindOnTypeChange(){
        const radios = document.getElementsByClassName('onTypeChange');
        for (let rd of radios){
            rd.addEventListener('change', () => this.onTypeChange());
        }
    }

    onTypeChange() {
        const type = document.querySelector('input[name=type]:checked').value;
        this.document_bloc.style.display = (type === "document") ? "block" : "none";
        this.gallery_bloc.style.display = (type === "gallery") ? "block" : "none";
        this.title_bloc.style.display = (type === "document") ? "block" : "none";

        this.file_document.required = (type === "document");
        this.files_gallery.required = (type === "gallery");
        this.file_title.required = (type === "document");
    }

    onAddFolderSubmit() {
        this.form_add_folder.addEventListener("submit",  (e) => {
            e.preventDefault();
            startLoading();

            for (let err of this.folder_errors) {
                err.innerHTML = "";
            }

            const formData = new FormData();
            formData.append("title", this.title.value);
            if (this.parent_folder.val() !== "0") formData.append("parent", this.parent_folder.val());

            Api.post(this.form_add_folder.dataset.route, {
                headers: {
                    "Accept": "application/json"
                },
                formData: formData,
                success: (xhr) => {
                    this.modale_add_folder.modal("hide");
                    showSuccess("Le dossier \""+this.title.value+"\" a bien été crée", function () {
                        location.reload();
                    });
                    this.form_add_folder.reset();

                },
                invalid: (xhr) => {
                    const jsonerror = JSON.parse(xhr.responseText);
                    if (jsonerror.errors){
                        Object.keys(jsonerror.errors).forEach(function(key) {
                            const target = document.getElementById("folder-error-"+key);
                            target.innerHTML = jsonerror.errors[key][0]
                        })
                    }
                }
            });

        })
    }

    onEditFolderSubmit() {
        this.form_edit_folder.addEventListener("submit",  (e) => {
            e.preventDefault();
            startLoading();

            for (let err of this.folder_edit_errors) {
                err.innerHTML = "";
            }

            const formData = new FormData();
            formData.append("title", this.edit_title.value);
            if (this.parent_edit_folder.val() !== "0") formData.append("parent", this.parent_edit_folder.val());

            Api.post(this.form_edit_folder.dataset.route, {
                headers: {
                    "Accept": "application/json"
                },
                formData: formData,
                success: (xhr) => {
                    this.modale_edit_folder.modal("hide");
                    showSuccess("Le dossier \""+this.title.value+"\" a bien été modifié. Vous allez etre redirigés ...", function () {
                        location.reload();
                    })
                    this.form_edit_folder.reset();

                },
                invalid: (xhr) => {
                    const jsonerror = JSON.parse(xhr.responseText);
                    if (jsonerror.errors){
                        Object.keys(jsonerror.errors).forEach(function(key) {
                            const target = document.getElementById("folder-edit-error-"+key);
                            target.innerHTML = jsonerror.errors[key][0]
                        })
                    }
                }
            });

        })
    }

    initFilePreview() {
        $('body').on('click', '.is--file', (e) =>{
            e.preventDefault();
            alert('mdr')
        })
    }

    updateGallery() {
        $('.js-zoom-mediatheque').each(function () {
            $(this).magnificPopup({
                delegate: 'a.file-name',
                type: 'image',
                gallery: {
                    enabled: true
                },
                removalDelay: 500, //delay removal by X to allow out-animation
                callbacks: {
                    elementParse: function(item) {
                        if(item.el[0].classList.contains('is--video')) {
                            console.log("video");
                            item.type = 'inline';
                            const videoUrl = item.src;
                            item.src = '<div class="mfp-close"></div>'+
                                '<video controls>'+
                                '<source src="'+videoUrl+'" type="video/mp4">'+
                                '</video>';
                        }

                        if(item.el[0].classList.contains('is--image')) {
                            console.log("image");
                            item.type = 'image';
                        };

                        if(item.el[0].classList.contains('is--file')) {
                            item.type = 'inline';
                            item.src = `<div class="mfp-close"></div><iframe style="width: 100%; height: 500px" src="https://docs.google.com/viewerng/viewer?url=${item.src}&hl=bn&embedded=true"></iframe>`;
                        };
                    },
                },
                /*callbacks: {
                    beforeOpen: function () {
                        // just a hack that adds mfp-anim class to markup
                        this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                        this.st.mainClass = 'mfp-zoom-in';
                    }
                },*/
                closeOnContentClick: false,
                midClick: true
            });
        });

    }
}
