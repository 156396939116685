export default class NotificationManager {

    constructor() {



        const siteHeader = document.getElementById("site-header");

        this.notificationsContainer = document.getElementById('notificationsContainer');

        this.page_loaded_at = parseInt(siteHeader.dataset.time);
        this.pullNotificationsUrl = siteHeader.dataset.pullnotification;
        this.alreadyShownNotifications = [];

        this.pullNotifications();
        setInterval(() => {this.pullNotifications()}, 1000 * 60); //1mn

        this.initMarkAllAsRead();
        this.initRequestBrowserNotifications()
    }

    pullNotifications() {
        Api.get(this.pullNotificationsUrl, {
            success: (xhr) => {
                this.notificationsContainer.innerHTML = xhr.responseText
                const notifs = this.notificationsContainer.querySelectorAll('.lgp-notif.un-read');
                notifs.forEach((notif) => {
                    if ((parseInt(notif.dataset.timestamp) > this.page_loaded_at) && !this.alreadyShownNotifications.includes(notif.id)){
                        var notification = new Notification(notif.dataset.title, {
                            icon: notif.dataset.image,
                            body: notif.dataset.body,
                            tag: notif.id
                        });
                        notification.onclick = function() {
                            window.open(notif.dataset.link);
                        };
                        this.alreadyShownNotifications.push(notif.id)
                    }
                });
            }
        });
    }

    initMarkAllAsRead() {
        const unread_count = document.getElementById("unreadCount");
        const notificationsContainer = this.notificationsContainer;
        $('body').on("click", '#markAllAsRead',  function (e)  {
            const url = $(this).data('route');
            Api.post(url, {
                success: (xhr) => {
                    notificationsContainer.innerHTML = xhr.responseText
                }
            })
        });
    }

    initRequestBrowserNotifications() {
        $('body').on("click", '.notif__request',  function (e)  {
            if (Notification.permission !== 'granted') Notification.requestPermission();
        });
    }
}
